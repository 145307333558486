import LoadingButton from '@mui/lab/LoadingButton';
import {Button, DialogActions, DialogContent, TextField} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import {Box} from '@mui/system';
import {DateTime} from 'luxon';
import {useEffect, useState} from 'react';
import {useAccounting} from '../../../../hooks/useAccounting';
import {Postage, usePayPostagesMutation} from '../../../../services';

export interface SimpleDialogProps {
  postages: Postage[]
  open: boolean;
  onClose: () => void;
}


export function PayPostageDialog({postages, onClose, open, ...props}: SimpleDialogProps) {
  const [pay, status] = usePayPostagesMutation()
  const [paymentDate, setPaymentDate] = useState<string>(DateTime.now().toFormat('dd/MM/yyyy'))
  const [message, setMessage] = useState<string>("PAYEMENT DU " + paymentDate)
  const {formatMoney} = useAccounting()
  useEffect(() => {
  }, [])

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    pay({
      postages: postages.map(p => p.id),
      paymentDate: DateTime.fromFormat(paymentDate, 'dd/MM/yyyy').toISODate()+'',
      label: message
    }).then(() => {
      handleClose();
    }).catch(console.error)
  }


  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Confirmer le payement</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': {m: 1},
          }}
          noValidate
          autoComplete="off"
        >
          <TextField fullWidth placeholder={"Libellé"} value={message} onChange={e => setMessage(e.target.value)}/>
          <TextField placeholder={"Date de paiement"} value={paymentDate} onChange={e => {

            setPaymentDate(e.target.value);
          }}/>
          <TextField disabled={true} value={formatMoney(postages.map(p => p.total).reduce((o, p) => o + p))}/>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">Annuler</Button>
        <LoadingButton onClick={handleSubmit} loading={status.isLoading} variant="contained">Valider</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
