import LoadingButton from '@mui/lab/LoadingButton';
import {Button, DialogActions} from '@mui/material';
import {Box} from '@mui/system';
import React, {Fragment, useEffect, useState} from 'react';
import {FormSelect, FormSelectItem} from '../../../../components/FormSelect';
import {useAccounting} from '../../../../hooks/useAccounting';
import {
  PatchPostage,
  Postage,
  useGetPostageCategoriesMutation,
  useGetPostageRatesMutation,
  usePatchPostageMutation
} from '../../../../services';
import {FormTextField} from "../../../../components/FormInput";

export interface SimpleDialogProps {
  postage?: Postage
  onClose: () => void;
}


export function EditPostageDialog({postage: originalPostage, onClose, ...props}: SimpleDialogProps) {
  const [getRates] = useGetPostageRatesMutation()
  const [rates, setRates] = useState<FormSelectItem[]>([])
  const [getCategories] = useGetPostageCategoriesMutation()
  const [categories, setCategories] = useState<FormSelectItem[]>([])
  const {formatMoney} = useAccounting()
  const [patch, status] = usePatchPostageMutation()
  const [postage, setPostage] = useState<Postage & PatchPostage | undefined>(originalPostage)

  useEffect(() => {
    getRates({year: 2025}).unwrap().then((r) => {
      setRates(r.map(d => ({label: d.label, value: d})))
    })
    getCategories().unwrap().then((r) => {
      setCategories(r.map(d => ({label: d.label, value: d})))
    })
  }, [])

  const handleClose = () => {
    setPostage(originalPostage)
    onClose && onClose();
  };


  const handleSubmit = () => {
    if (!originalPostage || !postage) {
      console.error('postage not defined')
      return
    }

    patch({
      id: originalPostage.id,
      condoId: originalPostage.condominium.id,
      label: postage.label,
      quantity: postage.quantity,
      rateId: postage.rate.id,
      categoryId: postage.category.id,
    }).unwrap().then((result) => {
      handleClose();
    }).catch(console.error)
  }

  const handleChange = (args: { [key: string]: any }) => {
    const p = {...postage, ...args}
    if (p && p.quantity) {
      p.total = p.quantity * (p.rate ? p.rate.rate : 0)
      // @ts-ignore
      setPostage(p)
    }
  };

  if (!originalPostage || !postage) {
    return <div>Sélectionnez un affranchissement.</div>
  }

  if (!rates || !categories) {
    return <div>Chargement...</div>
  }

  return (
    <Fragment>
      <Box
        component="form"
        noValidate
        autoComplete="off"
      >
        <FormTextField
          required
          fullWidth
          id="label"
          label="Libellé"
          value={postage.label}
          onChange={(e) => handleChange({label: e.target.value})}
        />

        <FormTextField
          required
          fullWidth
          id="quantity"
          label="Quantité"
          type="number"
          value={postage.quantity}
          onChange={(e) => handleChange({quantity: parseInt(e.target.value)})}
        />

        <FormSelect
          fullWidth
          label="Type de lettre"
          items={rates}
          value={rates ? rates.find(r => r.value.id === postage.rate.id)?.value : undefined}
          onChange={(e) => handleChange({rate: e.target.value})}
        />

        <FormSelect
          fullWidth
          label="Imputation"
          items={categories}
          value={categories ? categories.find(r => r.value.id === postage.category.id)?.value : undefined}
          onChange={(e) => handleChange({category: e.target.value})}
        />

        <DialogActions>
          <Box sx={{mr: 1}}>Total: {formatMoney(postage.total)}</Box>
          <Button variant="outlined" className="border-red-600 text-red-600 hover:border-red-800 hover:text-red-800" onClick={()=>handleClose()}>Annuler</Button>
          <LoadingButton onClick={handleSubmit} loading={status.isLoading} variant="contained" className="bg-green-600 hover:bg-green-800">Valider</LoadingButton>
        </DialogActions>
      </Box>
    </Fragment>
  );
}
