import {Select, styled} from "@mui/material";
import {useEffect} from "react";
import {FormSelect} from "./FormSelect";

export interface Year {
  label: string,
  value: number
}

interface Props {
  value: Year | undefined
  onSelect: (item: Year) => void
}

const StyledSelect = styled(Select)(({theme}) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const data = [{
  label: '2023',
  value: 2023
}, {
  label: '2024',
  value: 2024
}, {
  label: '2025',
  value: 2025
}]
export const YearSelect = ({value, onSelect}: Props) => {

  useEffect(() => {
    if (!value) {
      onSelect(data[data.length - 1])
    }
  }, [value])

  return <FormSelect
    items={data}
    value={value?.value}
    label="Année"
    onChange={e => {
      console.log('select year:', e.target.value)
      onSelect({
        label: e.target.value,
        value: e.target.value
      })
    }}
  />
}
